import {
  CancelTherapistInvitationRequest,
  ChangePasswordRequest,
  CreateUserRequest,
  GetUserViewRequest,
  InviteTherapistRequest,
  SendEmailVerificationActionLinkRequest,
  StopSharingWithTherapistRequest,
  UpdateUserProfileRequest,
  GenerateDashPasswordlessSigninLinkRequest,
  SearchMatchmakingProfessionalsRequest,
  GetNextMatchmakingResultPageRequest,
  SendVerificationEmailRequest,
  CheckVerificationEmailRequest,
  GetLaunchContextRequest,
} from './request-types'

export enum AppAuthenticatedRequestTypesEnum {

  GET_LAUNCH_CONTEXT = 'get-launch-context',
  CREATE_USER = 'create-user',
  GET_USER_VIEW = 'get-user-view',
  UPDATE_USER_PROFILE = 'update-user-profile',

  INVITE_THERAPIST = 'invite-therapist',
  CANCEL_THERAPIST_INVITATION = 'cancel-therapist-invitation',
  STOP_SHARING_WITH_THERAPIST = 'stop-sharing-with-therapist',

  SEND_EMAIL_VERIFICATION_ACTION_LINK = 'send-email-verification-action-link',
  GENERATE_DASH_PASSWORD_LESS_SIGN_IN_ACTION_LINK = 'generate-dash-password-less-signin-action-link',

  MATCHMAKING_SEARCH_PROFESSIONALS = 'matchmaking-search-professionals',
  MATCHMAKING_GET_NEXT_PAGE = 'matchmaking-get-next-page',

  /** @deprecated */
  SEND_VERIFICATION_EMAIL = 'send-verification-email',
  /** @deprecated */
  CHECK_VERIFICATION_EMAIL = 'check-verification-email',
}

export type AppAuthenticatedCFRequest = {
  type: AppAuthenticatedRequestTypesEnum
  request?: GetLaunchContextRequest
    | CreateUserRequest
    | GetUserViewRequest
    | UpdateUserProfileRequest
    | ChangePasswordRequest
    | InviteTherapistRequest
    | CancelTherapistInvitationRequest
    | StopSharingWithTherapistRequest
    | SendEmailVerificationActionLinkRequest
    | GenerateDashPasswordlessSigninLinkRequest
    | SearchMatchmakingProfessionalsRequest
    | GetNextMatchmakingResultPageRequest
    | SendVerificationEmailRequest
    | CheckVerificationEmailRequest
}
