import {
  BaseResponse,
  GenderCodesEnum,
  LanguageCodesEnum,
  MobilePhoneNumber,
  ProfessionalId,
  ProfessionalInformation,
  AppUserView,
  DashUserView,
  PrivateDashSettingsDBDocument
} from '../..'
import { BaseRequest } from '.'

export type UpdateAppUserProfileRequest = BaseRequest & {
  firstName?: string
  lastName?: string
  language?: LanguageCodesEnum
  gender?: GenderCodesEnum
  birthday?: string
  isTherapist?: boolean
  mobilePhoneNumber?: MobilePhoneNumber
  appOnboarding?: number
  appTermsVersionAccepted?: string
  privacyPolicyVersionAccepted?: string
}
export type UpdateAppUserProfileResponse = BaseResponse & {
  user?: AppUserView
}

export type UpdateDashUserProfileRequest = BaseRequest & {
  firstName?: string
  lastName?: string
  language?: LanguageCodesEnum
  gender?: GenderCodesEnum
  professionalId?: ProfessionalId
  professionalInformation?: ProfessionalInformation
  mobilePhoneNumber?: MobilePhoneNumber
  dashTermsVersionAccepted?: string
  privacyPolicyVersionAccepted?: string
  profilePicture?: string
}
export type UpdateDashUserProfileResponse = BaseResponse & {
  user: DashUserView
  privateSettings?: PrivateDashSettingsDBDocument
}

export type UpdateUserProfileRequest = UpdateAppUserProfileRequest
  | UpdateDashUserProfileRequest

export type UpdateUserProfileResponse = UpdateAppUserProfileResponse
  | UpdateDashUserProfileResponse

export type ChangePasswordRequest = BaseRequest & {
  password: string
}
export type ChangePasswordResponse = BaseResponse
