import { GenderCodesEnum, LanguageCodesEnum } from '../../../libs'
import {
  ScaleSetsEnum
} from '../enums'
import { BaseNotification } from './notification.type'
import { ScaleIds } from './psyedu.types'

export type BaseNotificationSchedule = BaseNotification & {
  readonly uid: string
  readonly email: string
  readonly firstName: string
  readonly language: LanguageCodesEnum
  readonly gender: GenderCodesEnum
  readonly createdAtDate: Date
  readonly menuNotifyAtDate?: Date
  readonly pushNotifyAtDate?: Date
  readonly emailNotifyAtDate?: Date
}

export type PsychoeducationModuleNotificationSchedule = BaseNotificationSchedule & {
  readonly title: string
  readonly moduleId: number
}

export type PsychoeducationScaleNotificationSchedule = BaseNotificationSchedule & {
  readonly scaleIds: ScaleIds
  readonly scaleSet: ScaleSetsEnum
}

export type IdentifiedNotificationSchedule = BaseNotificationSchedule & {
  readonly name: string
  readonly itemId: string
}
