import {
  AcceptPatientInvitationResponse,
  CancelTherapistInvitationResponse,
  ChangePasswordResponse,
  CreateAccountResponse,
  CreateUserResponse,
  GetHttpRedirectionResponse,
  GetUserViewResponse,
  RefusePatientInvitationResponse,
  SendEmailVerificationActionLinkResponse,
  SendMessageResponse,
  SendPasswordResetActionLinkResponse,
  StopSharingWithTherapistResponse,
  UpdateUserProfileResponse,
  ValidateCustomActionLinkResponse,
  ArchivePatientResponse,
  GenerateDashPasswordlessSigninLinkResponse,
  InvitePatientResponse,
  GetSubscriberInformationResponse,
  GrantPsychoeducationTrialResponse,
  GetLaunchContextResponse,
  SearchMatchmakingProfessionalsResponse,
  GetNextMatchmakingResultPageResponse,
  GetAppFormOptionsResponse,
  CheckVerificationEmailResponse,
  SendVerificationEmailResponse,
  GetPublicLaunchContextResponse,
  ReadTableDataFromBigQueryResponse,
  ExportBigQueryTableToRtdbResponse,
  GetUserInformationExResponse,
  ReplaceEmailInStripeEventsResponse,
  TerminateSubscriptionResponse,
  CancelSubscriptionResponse,
  GrantDashStudentSubscriptionResponse,
  ProcessExceptionStripeEventsResponse,
  DeleteUserResponse,
  GetUserInformationResponse,
  UpdateESInvoiceNumbersResponse,
  GrantDashTrialSubscriptionResponse,
} from './request-types'

export type DatabaseResponse = GetPublicLaunchContextResponse
  | GetLaunchContextResponse
  | CreateAccountResponse
  | GetUserViewResponse
  | CreateUserResponse
  | UpdateUserProfileResponse
  | ChangePasswordResponse
  | ValidateCustomActionLinkResponse
  | SendEmailVerificationActionLinkResponse
  | SendPasswordResetActionLinkResponse
  | AcceptPatientInvitationResponse
  | RefusePatientInvitationResponse
  | ArchivePatientResponse
  | InvitePatientResponse
  | CancelTherapistInvitationResponse
  | StopSharingWithTherapistResponse
  | SendMessageResponse
  | GetHttpRedirectionResponse
  | GenerateDashPasswordlessSigninLinkResponse
  | GetSubscriberInformationResponse
  | GrantPsychoeducationTrialResponse
  | SearchMatchmakingProfessionalsResponse
  | GetNextMatchmakingResultPageResponse
  | GetAppFormOptionsResponse
  | SendVerificationEmailResponse
  | CheckVerificationEmailResponse
  | ReadTableDataFromBigQueryResponse
  | ExportBigQueryTableToRtdbResponse
  | GetUserInformationResponse
  | GetUserInformationExResponse
  | ReplaceEmailInStripeEventsResponse
  | TerminateSubscriptionResponse
  | CancelSubscriptionResponse
  | GrantDashStudentSubscriptionResponse
  | ProcessExceptionStripeEventsResponse
  | DeleteUserResponse
  | UpdateESInvoiceNumbersResponse
  | GrantDashTrialSubscriptionResponse
