import {
  BaseResponse,
  GenderCodesEnum,
  MobilePhoneNumber,
  ProfessionalId,
  ProfessionalInformation,
  AppUserView,
  DashUserView,
  PrivateDashSettingsDBDocument,
} from '../..'
import { BaseRequest } from '.'

type CreateBaseUserRequest = BaseRequest & {
  firstName: string
  lastName: string
  gender: GenderCodesEnum
}

export type CreateAppUserRequest = CreateBaseUserRequest & {
  birthday: string
  isTherapist?: boolean
  mobilePhoneNumber?: MobilePhoneNumber
}
export type CreateAppUserResponse = BaseResponse & {
  user?: AppUserView
}

export type CreateDashUserRequest = CreateBaseUserRequest & {
  professionalId?: ProfessionalId
  professionalInformation?: ProfessionalInformation
  mobilePhoneNumber?: MobilePhoneNumber
  profilePicture?: string
}

export type CreateDashUserResponse = BaseResponse & {
  user: DashUserView
  privateSettings: PrivateDashSettingsDBDocument
}

export type CreateUserRequest = CreateAppUserRequest | CreateDashUserRequest
export type CreateUserResponse = CreateAppUserResponse | CreateDashUserResponse
