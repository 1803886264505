import { EmailStatusesEnum } from '../enums'

export type AuthInfo = {
  uid: string
  email: string
  creationTime: string
  emailId?: string
  emailStatus?: EmailStatusesEnum
  validationAttemptAtDate?: string
}
