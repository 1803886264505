import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  StatusEnum,
  ProductsEnum,
  DashAuthenticatedRequestTypesEnum,
  RtdbClient,
  GetLaunchContextRequest,
  GetDashLaunchContextResponse,
  PrivateDashSettingsDBDocument,
  AuthClient,
} from '../../../../libs';
import { CloudFunctionsService } from '../../../core';
import { SettingsModel } from '../types';
import { DashSettingsFactory } from '../factories';
import { UserService } from '../../user';
import { PatientsService } from '../../patients';
import { SubscriptionService } from '../../subscription';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  #settings: SettingsModel;
  #promotionalCode: string;

  private settingsSubject = new BehaviorSubject<SettingsModel>(undefined);
  public readonly settings$ = this.settingsSubject.asObservable();

  constructor(
    private functionsService: CloudFunctionsService,
    private defaultRtdbClient: RtdbClient,
    private authClient: AuthClient,
    private userService: UserService,
    private patientsService: PatientsService,
    private subscriptionService: SubscriptionService,
  ) {
  }

  async initializeAppForAuthenticatedUser() {
    await this.userService.setInitialUserState();
    await this.subscriptionService.setSubscriptionState(this.promotionalCode);
    this.patientsService.listenToCollections(this.userService.uid);
    await this.loadLaunchContext();
  }

  resetApp() {
    this.#settings = undefined;
  }

  set promotionalCode(code: string) {
    this.#promotionalCode = code;
  }

  get promotionalCode() {
    return this.#promotionalCode;
  }

  private async loadLaunchContext() {
    const request: GetLaunchContextRequest = {
      callerId: ProductsEnum.COGNI_DASHBOARD
    };
    const response = await this.functionsService.dashAuthenticatedCall({
      type: DashAuthenticatedRequestTypesEnum.GET_LAUNCH_CONTEXT,
      request
    });
    if (response.status === StatusEnum.OK) {
      this.#settings = DashSettingsFactory.reconstituteSettings(
        (response as GetDashLaunchContextResponse).privateSettings, (response as GetDashLaunchContextResponse).protectedSettings
      );
      this.userService.setInitialUserInformation((response as GetDashLaunchContextResponse).user)
      this.settingsSubject.next(this.#settings);
    }
  }

  async updateMhsNpsSurveyLastTakenAtDate() {
    this.#settings = DashSettingsFactory.updateMhsNpsSurveyLastTakenAtDate(this.#settings);
    const updatedSetting = {
      mhsNpsSurveyLastTakenAtDate: this.#settings.mhsNpsSurveyLastTakenAtDate
    };
    await this.updatePrivateSettings(updatedSetting);
    this.settingsSubject.next(this.#settings);
  }

  async updateSkipManifesto() {
    this.#settings = DashSettingsFactory.updateSkipManifesto(this.#settings);
    const updatedSetting = {
      skipManifesto: this.#settings.skipManifesto
    };
    await this.updatePrivateSettings(updatedSetting);
    this.settingsSubject.next(this.#settings);
  }

  async updateMatchmakingMock() {
    this.#settings = DashSettingsFactory.updateSkipMatchmakingMock(this.#settings);
    const updatedSetting = {
      skipMatchmakingMock: this.#settings.skipMatchmakingMock
    };
    await this.updatePrivateSettings(updatedSetting);
    this.settingsSubject.next(this.#settings);
  }

  setPrivateSettings(privateDashSettings: PrivateDashSettingsDBDocument) {
    this.#settings = DashSettingsFactory.updatePrivateSettings (
      this.#settings, privateDashSettings
    );
    this.settingsSubject.next(this.#settings);
  }

  get settings() {
    return this.#settings;
  }

  get bannerOption() {
    return this.#settings.bannerOption;
  }

  get interviewOption() {
    return this.#settings.interviewOption;
  }

  get noticeUponLaunch() {
    return this.#settings.noticeUponLaunch || null;
  }

  private async updatePrivateSettings(settings: any) {
    await this.defaultRtdbClient.updateChild(`/${this.privateDashSettingsDBPath}/${this.authClient.uid}`, settings);
  }

  private privateDashSettingsDBPath = 'privateDashSettings'
}
