export enum StatusEnum {
  OK = 'OK',
  ERROR = 'ERROR'
}

//lowercase/uppercase to match Firebase codes
export enum ErrorsEnum {
  NULLISH_PARAMETER = 'nullish-parameter',
  INVALID_PARAMETER = 'invalid-parameter',
  INVALID_OPERATION = 'invalid-operation',
  INVALID_CALLER_ID = 'invalid-caller-id',
  MISSING_PARAMETERS = 'missing-parameters',
  NOT_ALLOWED = 'not-allowed',
  NOT_IMPLEMENTED = 'not-implemented',
  MISSING_DATA_PROPERTY = 'missing-data-property',
  PATIENT_NOT_FOUND = 'patient-not-found',
  PATIENT_ACCOUNT_DELETED = 'patient-account-deleted',
  THERAPIST_NOT_FOUND = 'therapist-not-found',
  THERAPIST_ACCOUNT_DELETED = 'therapist-account-deleted',
  INVITATION_NOT_FOUND = 'invitation-not-found',
  INVITATION_ALREADY_EXISTS = 'invitation-already-exists',
  INTERCONNECTION_NOT_FOUND = 'interconnection-not-found',
  INTERCONNECTION_ALREADY_EXISTS = 'interconnection-already-exists',
  USER_NOT_FOUND = 'user-not-found',
  REPEATED_COGNI_CODE = 'repeated-cogni-code',
  UNABLE_TO_GET_REDIRECTION_URL = 'unable-to-get-redirection-url',
  UNABLE_TO_PROCESS_REQUEST = 'unable-to-process-request',
  UNEXPECTED_EMAIL_EVENT = 'unexpected-email-event',
  WILL_EXCEED_PATIENT_QUOTA = 'will-exceed-patient-quota',
  MATCHMAKING_REPEATED_PRO_ID = 'matchmaking-repeated-professional-id',
  MATCHMAKING_PRO_ID_NOT_FOUND = 'matchmaking-professional-id-not-found',
  NOT_ELIGIBLE_FOR_TRIAL = 'not-eligible-for-trial',
  INVALID_PROMOTIOAL_CODE = 'invalid-promotional-code',

  // sendgrid
  SENDGRIG_PROBLEM_SENDING_EMAIL = 'sendgrid/problem-sending-email',

  // stripe
  STRIPE_UNRECOGNIZED_EVENT = 'stripe/unrecognized-event',
  STRIPE_UNRECOGNIZED_STATUS = 'stripe/unrecognized-status',
  STRIPE_UNRECOGNIZED_FAILURE = 'stripe/unrecognized-failure',
  STRIPE_UNRECOGNIZED_PRODUCT = 'stripe/unrecognized-product',
  STRIPE_UNRECOGNIZED_PLAN = 'stripe/unrecognized-plan',
  STRIPE_UNRECOGNIZED_PREVIOUS_ATTRIBUTE = 'stripe/unrecognized-previous-attributes',
  STRIPE_UNRECOGNIZED_PAYMENT_LINK = 'stripe/unrecognized-payment-link',
  STRIPE_UNRECOGNIZED_PAYER = 'stripe/unrecognized-payer',
  STRIPE_UNRECOGNIZED_CHARGE = 'stripe/unrecognized-charge',
  STRIPE_MISSING_PAYER_EMAIL = 'stripe/missing-payer-email',
  STRIPE_MISSING_STATUS = 'stripe/missing-status',
  STRIPE_MISSING_TRANSACTION_DATE = 'stripe/missing-transaction-date',
  STRIPE_MISSING_PERIOD_END_DATE = 'stripe/missing-period-end-date',
  STRIPE_MISSING_FAILURE = 'stripe/missing-failure',
  STRIPE_MISSING_PAYMENT_LINK = 'stripe/missing-payment-link',
  STRIPE_MISSING_AMOUNT = 'stripe/missing-amount',
  STRIPE_MISSING_PRODUCT = 'stripe/missing-product',
  STRIPE_INVALID_INSTALLMENT_REFUND = 'stripe/invalid-installment-refund',
  STRIPE_DELAY_EVENT = 'stripe/delay-event',

  SUBSCRIPTION_NOT_FOUND = 'subscription-not-found',
  SUBSCRIPTION_ALREADY_TERMINATED = 'subscription-already-terminated',
  SUBSCRIPTION_ALREADY_CANCELLED = 'subscription-already-cancelled',

  // firebase
  AUTH_EMAIL_ALREADY_EXISTS = 'auth/email-already-exists',
  AUTH_EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  AUTH_EMAIL_EQUALS_CURRENT = 'auth/email-equals-current',
  AUTH_EMAIL_IMPROPERLY_FORMATTED = 'auth/email-improperly-formatted',
  AUTH_INVALID_USER_TOKEN = 'auth/invalid-user-token',
  AUTH_INVALID_ACTION_CODE = 'auth/invalid-action-code',
  AUTH_INVALID_ACTION_MODE = 'auth/invalid-action-mode',
  AUTH_NETWORK_REQUEST_FAILED = 'auth/network-request-failed',
  AUTH_REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login',
  AUTH_TOO_MANY_REQUESTS = 'auth/too-many-requests',
  AUTH_USER_DISABLED = 'auth/user-disabled',
  AUTH_USER_NOT_FOUND = 'auth/user-not-found',
  AUTH_EXPIRED_ACTION_CODE = 'auth/expired-action-code',
  AUTH_CUSTOM_ACTION_ALREADY_TAKEN = 'auth/custom-action-already-taken',
  AUTH_USER_UNAUTHENTICATED = 'auth/user-unauthenticated',
  AUTH_WRONG_PASSWORD = 'auth/wrong-password',
  AUTH_TOO_MANY_ATTEMPTS_TRY_LATER = 'auth/too-many-attempts-try-later',
  AUTH_PROBLEM_GETTING_EMAIL_VERIFICATION_LINK = 'action-link/problem-getting-email-verification-link',
  AUTH_PROBLEM_GETTING_PASSWORD_RESET_LINK = 'action-link/problem-getting-password-reset-link',

  AUTH_INTERNAL_TOO_MANY_ATTEMPTS_TRY_LATER = 'TOO_MANY_ATTEMPTS_TRY_LATER',
  AUTH_INTERNAL_EMAIL_ADDRESS_IMPROPERLY_FORMATTED = 'email address is improperly formatted',

  VERIFICATION_SMS_UNEXPECTED = 'VERIFICATION_SMS_UNEXPECTED',
  VERIFICATION_EMAIL_UNEXPECTED = 'verification/VERIFICATION_EMAIL_UNEXPECTED',
  VERIFICATION_EMAIL_WAIT_TO_TRY_AGAIN = 'verification/wait_to_try_again',
  VERIFICATION_EMAIL_TOO_MANY_ATTEMPTS = 'verification/too-many-attempts',

  UNKNOWN = 'unknown'
}
