import { BaseResponse, LanguageCodesEnum } from '../../..'
import { BaseRequest } from '.'

/** @deprecated */
export type SendVerificationEmailRequest = BaseRequest & {
  languageCode: LanguageCodesEnum
}
/** @deprecated */
export type SendVerificationEmailResponse = BaseResponse

/** @deprecated */
export type CheckVerificationEmailRequest = BaseRequest & {
  code: string,
}

/** @deprecated */
export type CheckVerificationEmailResponse = BaseResponse & {
  verified: boolean,
  canTryAgain: boolean,
}
