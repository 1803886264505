import { CurrenciesEnum, GenderCodesEnum, LanguageCodesEnum, MatchmakingProfessionCodesEnum } from '../enums'

export type MatchmakingContactDBDocument = {
  patientUid: string
  patientGender: GenderCodesEnum
  patientName: string
  patientWhastApp: string | null
  patientBirthday: string | null
  patientLanguage: LanguageCodesEnum
  professionalUid: string
  professionalIdType: string
  professionalId: string
  professionalGender: GenderCodesEnum
  professionalName: string
  professionalWhatsApp: string
  currency: CurrenciesEnum
  sessionPrice: number
  profession: MatchmakingProfessionCodesEnum
  graduationYear: number
  approach: string
  specialty: string | null
  focus: string
  country: string
  initiatedAtDate: Date
  searchId: string
}

export type MatchmakingContactEventDBDocument = {
  uid: string
  professionalId: string
  contactedAtDate: Date
  searchId: string
  page: number
  position: number
  deleteAtDate: Date
}
